exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-akcijos-pasiulymai-tsx": () => import("./../../../src/pages/akcijos-pasiulymai.tsx" /* webpackChunkName: "component---src-pages-akcijos-pasiulymai-tsx" */),
  "component---src-pages-apie-mus-tsx": () => import("./../../../src/pages/apie-mus.tsx" /* webpackChunkName: "component---src-pages-apie-mus-tsx" */),
  "component---src-pages-briaunos-pasirinkimas-tsx": () => import("./../../../src/pages/briaunos-pasirinkimas.tsx" /* webpackChunkName: "component---src-pages-briaunos-pasirinkimas-tsx" */),
  "component---src-pages-corian-stalvirsiai-tsx": () => import("./../../../src/pages/corian-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-corian-stalvirsiai-tsx" */),
  "component---src-pages-dirbtinio-akmens-plautuves-praustuvai-tsx": () => import("./../../../src/pages/dirbtinio-akmens-plautuves-praustuvai.tsx" /* webpackChunkName: "component---src-pages-dirbtinio-akmens-plautuves-praustuvai-tsx" */),
  "component---src-pages-dirbtinio-akmens-stalvirsiai-tsx": () => import("./../../../src/pages/dirbtinio-akmens-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-dirbtinio-akmens-stalvirsiai-tsx" */),
  "component---src-pages-himacs-stalvirsiai-tsx": () => import("./../../../src/pages/himacs-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-himacs-stalvirsiai-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kiti-stalvirsiai-tsx": () => import("./../../../src/pages/kiti-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-kiti-stalvirsiai-tsx" */),
  "component---src-pages-privatumo-politika-tsx": () => import("./../../../src/pages/privatumo-politika.tsx" /* webpackChunkName: "component---src-pages-privatumo-politika-tsx" */),
  "component---src-pages-virtuves-stalvirsiai-tsx": () => import("./../../../src/pages/virtuves-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-virtuves-stalvirsiai-tsx" */),
  "component---src-pages-vonios-stalvirsiai-tsx": () => import("./../../../src/pages/vonios-stalvirsiai.tsx" /* webpackChunkName: "component---src-pages-vonios-stalvirsiai-tsx" */)
}

