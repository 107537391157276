import React from 'react';
import ReactDOM from 'react-dom/client';
import { register } from 'swiper/element/bundle';
import 'normalize.css';

import GlobalStyles from './src/static/styles/global.css';

export const onClientEntry = () => {
  register(); // Register swiper.js
};

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    {element}
  </>
);

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
